import { directus } from "../../lib/directus";
style {
  .form-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;
    position: relative;
  }

  input,
  textarea {
    border-radius: 4px;
    padding: 20px;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    border: 2px solid transparent;
  }

  .button {
    background-color: var(--accent);
    color: var(--accent-color);
    padding: 20px;
    border-radius: 4px;
    font-size: 18px;
    display: flex;
    place-content: space-between;
    font-weight: 800;
    transition: box-shadow 0.5s, color 0.5s, background-color 0.5s;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
  }

  .button p {
    font-weight: 800;
  }

  .button:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0);
    background-color: var(--green);
    color: white;
  }

  .input-error {
    border: solid 2px var(--error);
  }

  .form-message-error,
  .form-message-success {
    padding: 20px;
    font-size: 16px;
    border-radius: 4px;
  }

  .form-message-success {
    background-color: var(--green);
    color: white;
  }

  .form-message-error {
    background-color: white;
    color: var(--error);
  }
}
class {
  onCreate() {
    this.state = {
      validName: true,
      validMessage: true,
      formValues: {},
      error: false,
      success: false,
    };
  }
  async submit() {
    this.state.validName = true;
    this.state.validMessage = true;
    if (!this.state.formValues.name?.length > 0) {
      this.state.validName = false;
    }
    if (!this.state.formValues.message?.length > 0) {
      this.state.validMessage = false;
    }

    if (this.state.validMessage && this.state.validName) {
      try {
        await directus.items("ContactForm").createOne({
          name: this.state.formValues.name,
          message: this.state.formValues.message,
          email: this.state.formValues.email,
          phone: this.state.formValues.phone,
        });
        this.state.formValues = {};
        this.state.success = true;
        this.state.error = false;
      } catch (err) {
        this.state.success = false;
        this.state.error = true;
        console.log(err);
      }
    }
  }
  setValue(name, e) {
    const value = e.target.value;
    this.state.formValues[name] = value;
  }
}
div.form-block
  input [
    placeholder="Name"
    class={ "input-error": !state.validName }
    on-input("setValue", "name")
  ]
  input placeholder="Phone" on-input("setValue", "phone")
  input placeholder="Email" on-input("setValue", "email")
  textarea [
    placeholder="Message"
    rows="8"
    cols="40"
    class={ "input-error": !state.validMessage }
    on-input("setValue", "message")
  ]
  div.button on-click("submit")
    p -- Submit
    feather-icon icon="chevron-right"
  if(state.success)
    div.form-message-success
      p -- Success! Thanks for your message.
  if(state.error)
    div.form-message-error
      p -- Uh oh. We weren't able to send your message.
